import * as logClient from "@classdojo/log-client";
import { paths } from "@classdojo/ts-api-types";
import {
  InfiniteData,
  Query,
  QueryKey,
  InvalidateQueryFilters,
  InvalidateOptions,
  QueryFilters,
  SetDataOptions,
  CancelOptions,
} from "@tanstack/react-query";
import callApi from "@web-monorepo/infra/callApi";
import { APIResponseError } from "@web-monorepo/infra/responseHandlers";
import { produce } from "immer";
import chunk from "lodash/chunk";
import isEqual from "lodash/isEqual";
import { useCallback, useMemo } from "react";
import { APIResponse, EndpointParams, EndpointQueryParameters } from "../api/apiTypesHelper";
import { makeApiErrorMessage } from "./error";
import { useInfiniteQuery, UseInfiniteQueryOptions, InfiniteQueryObserverResult } from "./hacks";
import { queryClient } from "./queryClient";
import { registerQuery, makeQueryKey } from "./queryKey";
import { mergeFns, useQueryScope } from "./queryUtils";
import { buildUrl } from "./urlBuilder";
import { buildHeaders } from "./headersBuilder";
import { urlPatternFromFetcher } from "./urlPatternFromFetcher";
import { NOOP, WAITING_FOR_DEPENDENCIES, CUSTOM_HEADERS } from ".";

type CollectionQueryParams<
  Path extends keyof paths,
  QueryParams extends keyof EndpointQueryParameters<Path>,
> = EndpointParams<Path, QueryParams> & {
  [CUSTOM_HEADERS]?: Record<string, string>;
};
type FullCollectionQueryParams<Params> = Params | typeof NOOP | typeof WAITING_FOR_DEPENDENCIES;

// React Query options exposed at the configuration level. Some options are
// facilitated by makeCollectionQuery and some would cause unexpected behavior with
// the internal logic.
type ExposedUseQueryOptions<MemberType> = Omit<
  // Technically this should be UseInfiniteQueryOptions, but: https://github.com/TanStack/query/issues/3065.
  // For now, this doesn't really matter as we wouldn't be exposing any infinite query options anyway.
  UseInfiniteQueryOptions<DojoCollectionPage<MemberType>, APIResponseError, MemberType[]>,
  | "queryKey"
  | "queryFn"
  | "enabled"
  | "meta"
  | "queryKeyHashFn"
  | "useErrorBoundary"
  | "select"
  | "onSuccess"
  | "onError"
  | "onSettled"
  | "getNextPageParam"
  | "defaultPageParam"
>;

type EnhancedUseQueryConfig<MemberType, Params> = ExposedUseQueryOptions<MemberType> & {
  onSuccess?: (data: MemberType[], params: Params) => void;
  onError?: (error: Error, params: Params) => void;
  onSettled?: (data: MemberType[] | undefined, error: Error | null, params: Params) => void;
  select?: (data: MemberType[]) => MemberType[];
  enabled?: boolean;
};

type EnhancedUseQueryOptions<MemberType, Params> = Omit<EnhancedUseQueryConfig<MemberType, Params>, "select">;

type CollectionQueryData<Path extends keyof paths> = "get" extends keyof paths[Path]
  ? APIResponse<Path, "get"> extends { _items: unknown[] }
    ? APIResponse<Path, "get">["_items"][number]
    : never
  : never;

export type CollectionQueryType<
  Path extends keyof paths,
  QueryParams extends keyof EndpointQueryParameters<Path>,
  MemberType = CollectionQueryData<Path>,
  Params = CollectionQueryParams<Path, QueryParams>,
> = {
  (
    params: FullCollectionQueryParams<Params>,
    options?: EnhancedUseQueryOptions<MemberType, Params>,
  ): InfiniteQueryObserverResult<MemberType[]>;

  invalidateQueries(
    params?: Partial<CollectionQueryParams<Path, QueryParams>>,
    filters?: Omit<InvalidateQueryFilters, "queryKey">,
    options?: InvalidateOptions,
  ): Promise<void>;

  shouldInvalidateQueries(
    shouldInvalidateQuery: (
      data: MemberType[] | undefined,
      params: CollectionQueryParams<Path, QueryParams>,
    ) => boolean | null | undefined,
  ): void;

  cancelQueries(
    params?: Partial<CollectionQueryParams<Path, QueryParams>>,
    filters?: Omit<QueryFilters, "queryKey">,
    options?: CancelOptions,
  ): Promise<void>;

  getQueryData(params?: CollectionQueryParams<Path, QueryParams>): MemberType[] | undefined;

  getQueriesData(
    params?: Partial<CollectionQueryParams<Path, QueryParams>>,
    filters?: Omit<InvalidateQueryFilters, "queryKey">,
  ): [QueryKey, MemberType[] | undefined][];

  setQueriesData(
    updater: ((draft: MemberType[]) => MemberType[] | void) | MemberType[] | null,
    params?: Partial<CollectionQueryParams<Path, QueryParams>>,
    filters?: Omit<QueryFilters, "queryKey">,
    options?: SetDataOptions,
  ): void;
};

export type DojoCollectionPage<T = unknown> = {
  _items: T[];
  _links?: {
    prev: { href: string };
    next: { href: string };
  };
  _metadata?: Record<string, unknown>;
  [key: string]: unknown;
};

const flattenInfiniteData = <T>(data: InfiniteData<DojoCollectionPage<T>> | undefined): T[] => {
  return data?.pages.flatMap(({ _items }) => _items) ?? [];
};

const unflattenCollection = <T>(
  collection: T[] | null,
  data?: InfiniteData<DojoCollectionPage<T>>,
): InfiniteData<DojoCollectionPage<T>> | undefined => {
  if (!data) {
    // If there is no data, no fetches have been completed from the server. However, if there is a collection
    // that means that member(s) were inserted client-side. In that case, return cache data that represents a
    // single "fetched" page, but no page param because we don't know where to fetch it from. With the pageParam
    // being undefined, subsequent fetches will use the firstPageUrl.
    return collection
      ? {
          pages: [{ _items: collection }],
          pageParams: [undefined],
        }
      : undefined;
  }

  const numPagesLoaded = data.pages.length;
  const itemsPerPage = Math.ceil((collection?.length ?? 0) / numPagesLoaded);

  const chunks = chunk(collection, itemsPerPage);
  const pages = data.pages.map((page, index) => ({
    ...page,
    _items: chunks[index] ?? [],
  }));

  return {
    ...data,
    pages,
  };
};

// # Regarding variedHeaders:
// Sometimes an HTTP request header can influence the contents of the HTTP response.
// In those cases, the `Vary` response header should include a list of request headers
// that influenced the response content. Ideally, we'd use that to determine which
// request headers need to be a part of the React Query cacheKey, but React Query wants
// you to provide the cacheKey upfront before the request is ever made. Instead, we're
// maintaining a list here as this is an edge case currently.

type CollectionQueryConfig<
  Path extends keyof paths,
  QueryParams extends keyof EndpointQueryParameters<Path>,
  MemberType extends CollectionQueryData<Path> = CollectionQueryData<Path>,
  Params extends CollectionQueryParams<Path, QueryParams> = CollectionQueryParams<Path, QueryParams>,
> = {
  fetcherName: string;
  path: Path;
  defaultHeaders?: Record<string, string>;
  variedHeaders?: string[];
  query?: Omit<EndpointQueryParameters<Path>, QueryParams>;
  queryParams?: QueryParams[];
  dontThrowOnStatusCodes?: number[];
} & EnhancedUseQueryConfig<MemberType, Params>;

export function makeCollectionQuery<
  Path extends keyof paths,
  QueryParams extends keyof EndpointQueryParameters<Path> = never,
  MemberType extends CollectionQueryData<Path> = CollectionQueryData<Path>,
  Params extends CollectionQueryParams<Path, QueryParams> = CollectionQueryParams<Path, QueryParams>,
>(
  config: CollectionQueryConfig<Path, QueryParams, MemberType, Params>,
): CollectionQueryType<Path, QueryParams, MemberType, Params> {
  const {
    path,
    query,
    queryParams,
    defaultHeaders,
    variedHeaders,
    dontThrowOnStatusCodes = [],
    fetcherName,
    onSuccess: onSuccessConfig,
    onError: onErrorConfig,
    onSettled: onSettledConfig,
    select: selectConfig,
    ...extraConfig
  } = config;

  const urlPattern = urlPatternFromFetcher(path, query, queryParams);

  registerQuery(fetcherName);

  // The actual custom hook pre-configured
  // eslint-disable-next-line complexity
  function useInfiniteQueryWrapper(
    params: FullCollectionQueryParams<Params> = {} as Params,
    options: EnhancedUseQueryOptions<MemberType, Params> = {},
  ) {
    const { onSuccess: onSuccessOption, onError: onErrorOption, onSettled: onSettledOption, ...extraOptions } = options;

    const queryKey = useMemo(() => makeQueryKey({ fetcherName, params, variedHeaders }), [params]);

    const awaitingDependencies = params === WAITING_FOR_DEPENDENCIES;
    const isNoop = params === NOOP;
    const firstPageUrl = !isNoop && !awaitingDependencies ? buildUrl({ params, urlPattern }, true) : undefined;
    const headers = !isNoop && !awaitingDependencies ? buildHeaders(defaultHeaders, params[CUSTOM_HEADERS]) : undefined;
    const enabled = !isNoop && !awaitingDependencies && Boolean(firstPageUrl);

    const select = useCallback((data: InfiniteData<DojoCollectionPage<MemberType>> | undefined) => {
      const flatData = flattenInfiniteData(data);
      if (selectConfig && flatData) {
        return selectConfig(flatData);
      } else {
        return flatData;
      }
    }, []);

    const { queryFn, onSuccess, onError, onSettled } = useQueryScope<DojoCollectionPage<MemberType>, MemberType[]>({
      queryFn: async ({ pageParam: pageUrl }) => {
        const requestUrl = pageUrl ?? firstPageUrl;

        // We have an issue with some queries being triggered when
        // they are not enabled (in the NOOP state). "enabled === false"
        // only stops a query from being *automatically* fetched. If
        // someone were to call refetch/fetchNextPage while the query is
        // in a NOOP state, the queryFn will run and the URL will be undefined.
        // This issue further compounds with how redirects work on prod. On
        // prod, URLs like https://teach.classdojo.com/undefined will redirect to
        // https://teach.classdojo.com/#/undefined and produce a 200 response, but
        // the response will be html/text and won't get parsed into response.body.
        // Even if it did, it wouldn't contain a collection. The right thing to
        // do is never end up in this situation, the next best thing is to return
        // an empty collection as the result will not be used anyway. If someone
        // wants to fix this in the future, add some logging here with the
        // fetcherName in hopes you can find usages that might cause this. One
        // example is with messages where the fetchNextPage fn is throttled and a
        // call gets scheduled, but then when executed is in a NOOP state.
        if (!requestUrl) {
          return {
            _items: [],
          };
        }

        try {
          const response = await callApi({
            method: "GET",
            path: requestUrl,
            headers,
          });

          // We should be able to remove this by preventing queries with an undefined URL from making it
          // to callApi, but I want to see this error disappear before trusting the above solution.
          if (!response.body) {
            logClient.logException(new Error(`Empty response body returned for fetcher: ${fetcherName}`), {
              params,
              requestUrl,
              responseStatus: response.status,
              responseContentType: response.headers?.["content-type"],
              bodyType: typeof response.body,
              responseTextLength: response.text?.length,
              responseStartLooksLikeJson: response.text?.startsWith("{") || response.text?.startsWith("["),
              responseStartLooksLikeHtml: response.text?.startsWith("<"),
              responseEndLooksLikeJson: response.text?.endsWith("}") || response.text?.endsWith("]"),
              responseEndLooksLikeHtml: response.text?.endsWith(">"),
            });
            return {
              _items: [],
            };
          }

          return response.body;
        } catch (ex: unknown) {
          if (!(ex instanceof Error)) {
            throw ex;
          }

          if (ex instanceof APIResponseError) {
            ex.isExpected = dontThrowOnStatusCodes.includes(ex.response.statusCode);

            const headingMessage = ex.isExpected
              ? "API error in collectionFetcher, caught and treated as expected"
              : "Unexpected API error in collectionFetcher";

            ex.message = makeApiErrorMessage({
              headingMessage,
              response: ex.response,
              name: fetcherName,
              parsedUrl: requestUrl,
              type: "Collection",
            });
          }

          throw ex;
        }
      },
      onSuccess: (data) => {
        onSuccessConfig?.(data, params as Params);
      },
      onError: (error) => {
        onErrorConfig?.(error, params as Params);
      },
      onSettled: (data, error) => onSettledConfig?.(data, error, params as Params),
    });

    const result = useInfiniteQuery<DojoCollectionPage<MemberType>, APIResponseError, MemberType[]>({
      queryKey,
      queryFn,
      onSuccess: mergeFns(onSuccess, (data) => onSuccessOption?.(data, params as Params)),
      onError: mergeFns(onError, (error) => onErrorOption?.(error, params as Params)),
      onSettled: mergeFns(onSettled, (data, error) => onSettledOption?.(data, error, params as Params)),
      enabled,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retryOnMount: false,
      staleTime: Infinity,
      retry: false,
      // react-query uses a paged data structure for infinite queries.
      // Select allows us to massage the shape of the data while
      // maintaining the paged data structure in the react-query cache.
      // The select function is run whenever new data is fetched or if
      // the fn reference passed in changes.
      select,
      getNextPageParam: (lastPage) => {
        return lastPage?._links?.next?.href;
      },
      getPreviousPageParam: (firstPage) => {
        const prevPageHref = firstPage?._links?.prev?.href;
        const nextPageHref = firstPage?._links?.next?.href;
        return prevPageHref !== nextPageHref ? prevPageHref : undefined;
      },
      ...extraConfig,
      ...extraOptions,
    });

    if (result.error && !result.error.isExpected) {
      throw result.error;
    }

    return result;
  }

  useInfiniteQueryWrapper.shouldInvalidateQueries = (
    shouldInvalidateQuery: (
      data: MemberType[] | undefined,
      params: CollectionQueryParams<Path, QueryParams>,
    ) => boolean | null | undefined,
  ): void => {
    const queriesData = queryClient.getQueriesData<InfiniteData<DojoCollectionPage<MemberType>>>({
      queryKey: makeQueryKey({ fetcherName }),
    });
    queriesData.forEach(([queryKey, data]) => {
      const [, params] = queryKey as [string, CollectionQueryParams<Path, QueryParams>];
      if (shouldInvalidateQuery(flattenInfiniteData(data), params)) {
        useInfiniteQueryWrapper.invalidateQueries(params);
      }
    });
  };

  useInfiniteQueryWrapper.invalidateQueries = async (
    params?: Partial<CollectionQueryParams<Path, QueryParams>>,
    filters?: Omit<InvalidateQueryFilters, "queryKey">,
    options?: InvalidateOptions,
  ): Promise<void> => {
    return queryClient.invalidateQueries({ queryKey: makeQueryKey({ fetcherName, params }), ...filters }, options);
  };

  useInfiniteQueryWrapper.cancelQueries = async (
    params?: Partial<CollectionQueryParams<Path, QueryParams>>,
    filters?: Omit<QueryFilters, "queryKey">,
    options?: CancelOptions,
  ): Promise<void> => {
    return queryClient.cancelQueries({ queryKey: makeQueryKey({ fetcherName, params }), ...filters }, options);
  };

  useInfiniteQueryWrapper.getQueryData = (
    params?: CollectionQueryParams<Path, QueryParams>,
  ): MemberType[] | undefined => {
    const queryKey = makeQueryKey({ fetcherName, params });
    return flattenInfiniteData(queryClient.getQueryData<InfiniteData<DojoCollectionPage<MemberType>>>(queryKey));
  };

  useInfiniteQueryWrapper.getQueriesData = (
    params?: Partial<CollectionQueryParams<Path, QueryParams>>,
    filters?: Omit<InvalidateQueryFilters, "queryKey">,
  ): [QueryKey, MemberType[] | undefined][] => {
    const queriesData = queryClient.getQueriesData<InfiniteData<DojoCollectionPage<MemberType>>>({
      queryKey: makeQueryKey({ fetcherName, params }),
      ...filters,
    });
    return queriesData.map(([queryKey, data]) => [queryKey, flattenInfiniteData(data)]);
  };

  useInfiniteQueryWrapper.setQueriesData = (
    updater: ((draft: MemberType[]) => MemberType[] | void) | MemberType[] | null,
    params?: Partial<CollectionQueryParams<Path, QueryParams>>,
    filters?: Omit<QueryFilters, "queryKey">,
    options?: SetDataOptions,
  ): void => {
    const searchByQueryKey = makeQueryKey({ fetcherName, params });
    const queriesToUpdate: any[] = queryClient.getQueryCache().findAll({ queryKey: searchByQueryKey, ...filters });

    const wrappedUpdater = (data: MemberType[]): MemberType[] | null => {
      if (updater instanceof Function) {
        return produce(data, updater);
      } else {
        return updater;
      }
    };

    queriesToUpdate.forEach(async (query: Query<InfiniteData<DojoCollectionPage<MemberType>>>) => {
      const { state, queryKey } = query;
      const { data, fetchStatus } = state;
      const flattened = flattenInfiniteData(data);
      if (!flattened) return;

      const reduced = wrappedUpdater(flattened);
      if (isEqual(data, reduced)) return;

      const isFetching = fetchStatus === "fetching";
      if (isFetching) {
        await queryClient.cancelQueries({ queryKey, exact: true });
      }

      const unflattened = unflattenCollection(reduced, data);
      queryClient.setQueriesData({ queryKey, exact: true }, unflattened, options);

      if (isFetching) {
        await queryClient.refetchQueries({ queryKey, exact: true });
      }
    });
  };

  return useInfiniteQueryWrapper;
}
