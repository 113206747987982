import { captureException } from "./init";
import type { Params, Path } from "@sentry/react/types/types";

type Args = {
  useLocation: () => Path;
  useUrlParams: <P extends never>(path?: P) => Params[P];
  useSearchParams: () => [Iterable<[string, string]>, any];
  useWatch: (deps: [string], callback: () => void) => void;
  setTag: (tagName: string, value: string) => void;
};

export const useUrlTags = ({
  useLocation,
  useUrlParams,
  useSearchParams,
  useWatch,
  setTag,
}: Args) => {
  const { pathname, search } = useLocation();
  const urlParams = useUrlParams();
  const [searchParams] = useSearchParams();

  useWatch([pathname], () => {
    try {
      setTag("dojo_pathname", pathname);
      if (urlParams && typeof urlParams != "string") {
        const pathGroup = calculatePathGroup(urlParams, pathname);
        setTag("dojo_pathgroup", pathGroup);
      }
      // we do not want this to have user impact
      // eslint-disable-next-line no-catch-all/no-catch-all
    } catch (e: any) {
      gracefullyHandleTelemetryError(e);
    }
  });
  useWatch([search], () => {
    try {
      const paramsList: string[] = [];
      for (const [key] of searchParams) {
        paramsList.push(key);
      }
      setTag("dojo_search_params", paramsList.join(","));
      // we do not want this to have user impact
      // eslint-disable-next-line no-catch-all/no-catch-all
    } catch (e: any) {
      gracefullyHandleTelemetryError(e);
    }
  });
};

export const calculatePathGroup = (
  params: Record<string, string | undefined>,
  pathname: string
) => {
  const splitPath = pathname.split("/");
  const reverseParams: Record<string, string> = Object.keys(params).reduce(
    (reversed, param: string) => {
      const reversedKey: string = params[param] || "";
      return { ...reversed, [reversedKey]: param };
    },
    {}
  );
  return splitPath.reduce((newPath: string, section) => {
    if (!section) {
      return newPath;
    }
    const replacedSection = reverseParams[section]
      ? `:${reverseParams[section]}`
      : section;
    return `${newPath}/${replacedSection}`;
  });
};

const gracefullyHandleTelemetryError = (e: Error) => {
  // we do not want this to bubble up and stop the component from rendering
  captureException(e, {
    extra: { message: "error tagging urls in for telemetry" },
  });
};
